.FiltersContainer{
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 4px #e2dede;
}

.FiltersGrid{
    flex: 1;
}

.FilterGrid{
    width: 18%;
    padding-right: 2%
}

.DefaultBtn{
    width: 145px;
    height: 45px;
    background-color: #14345A !important;
    text-transform: capitalize !important;
    color: #ffffff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.SessionsContainer{
    background-color: #ffffff;
    border-radius: 8px;
    margin-top: 20px
}

.SessionsLabel{
    height: 65px;
    padding: 0 30px;
}

.TableTitle{
    font-size: 20px;
    color: #333333;
}

.FilterInput{
    height: 45px;
    padding: 0 40px 0 15px !important;
}

.FilterRoot label{
    transform: translate(14px, 15px) scale(1);
    font-size: 16px;
    font-weight: normal;
    color: #9A9A9A;
    
}

.FilterInput input {
    height: 45px;
    padding: 0 !important;
    font-size: 16px;
    font-weight: normal;
    color: #333333;
}

.FilterInput fieldset{
    border: 0.5px solid #A8B9D6;
}

.SelectOption{
    font-size: 14px;
    color: #333333;
}

.DateRoot{
    width: 100%;
    height: 45px;
    padding: 0 0 0 15px !important;
    border: 0.5px solid #A8B9D6 !important;
    border-radius: 4px;
    margin: 0 !important;
}

.DateRoot label{
    font-size: 16px;
    font-weight: normal;
    color: #9A9A9A;
    top: -13px;
    left: auto;
    background-color: #fff;
    padding: 3px;
}

.DateRoot label.Mui-focused {
    top: -10px
}

.DateFocus{
    font-size:20px;
}

.DateRoot > div{
    margin-top: 0 !important;
    height: 45px;
}

.DateRoot > div:before,
.DateRoot > div:after,
.DateRoot > div:hover:before,
.DateRoot > div:hover:after{
    border-bottom: none !important;
}

.DateRoot input {
    font-size: 16px;
    font-weight: normal;
    color: #9A9A9A;
    padding: 12px 0;
}

.SvgIcon{
    width: 17px;
    height:17px;
    vertical-align: middle;
}

.ActionButton{
    background-color: unset;
    box-shadow: 0 1px 8px rgba(159, 163, 168, 0.1);
    border: 1px solid #E5E9EB;
    cursor: pointer;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    padding: 0;
    border-radius: 5px;
}

.PerPage{
    font-size: 14px;
    margin-right: 15px;
    color: #9A9A9A;
}

.PerPageSelect{
    width: 74px !important;
    height: 30px !important;
    border: 0.5px solid #A8B9D6 !important;
    border-radius: 4px !important;
    padding: 0 0 0 10px !important;
}

.PaginationText{
    margin-left: 20px;
    margin-right: 20px;
}

.PerPageMain:before,
.PerPageMain:after,
.PerPageMain:hover:before,
.PerPageMain:hover:after{
    border-bottom: none !important;
}

.DeletPaperScrollPaper{
    padding: 35px;
    text-align: center;
}

.DeleteSessionImg{
    width: 66px;
    height: 66px;
}

.DeletPaperScrollPaper h2{
    color: #333333;
    font-size: 24px;
    font-weight: 400;
    margin: 40px 0 10px;
}

.DeletPaperScrollPaper p{
    font-size: 16px;
    color: #707070;
}

.DailogBtnGrp{
    margin-top: 45px;
}

.DailogBtn{
    width: 137px;
    height: 47px;
    font-size: 18px !important;
    color: #333333 !important;
    text-transform: capitalize !important;
}

.DailogNoBtn{
    border: 1px solid #DADADA !important;
    margin-right: 15px !important;
}

.DailogYesBtn{
    background-color: #F27979 !important;
    border: 1px solid #F27979 !important;
    color: #ffffff !important;
}

.TableRow{
    cursor: pointer;
}

.TableRow:hover{
    background-color: #f9f9f9;
}