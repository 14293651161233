.SlideContainer {
    width: 100%;
    margin: auto;
    height: calc(100% - 63px);
    border: 1px solid #CECECE;
    border-radius: 9px 9px 0 0;
    position: relative;
    /* background-color: #ffffff;  */
}

.SlideContainer div{
    height: 100%;
    background-size: cover;
    /* width: 80%;
    margin: auto; */
}

.SlideContainer .slide{
  width: 83%;
  margin: auto;
}
  
  h3 {
    text-align: center; }
  
  .Slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 300px;
  }
  
  .Slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .ImageDiv{
    background-size: contain !important; 
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .each-fade {
    display: flex;
  }
  
  .each-fade .image-container {
    width: 75%;
    overflow: hidden;
  }
  
  .each-fade .image-container img {
    width: 100%;
  }
  
  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }

  .Player{
    width: 100%;
    margin: auto;
    padding: 12px 45px;
    border: 1px solid #CECECE;
    border-top-width: 0;
    border-radius: 0 0 9px 9px;
    /* background-color: #ffffff; */
  }


  .DownloadPaperScrollPaper{
    padding: 35px;
    text-align: center;
}

/* .DeleteSessionImg{
    width: 66px;
    height: 66px;
} */

.DownloadPaperScrollPaper h2{
    color: #333333;
    font-size: 24px;
    font-weight: 400;
    margin: 40px 0 10px;
}

.DownloadPaperScrollPaper p{
    font-size: 16px;
    color: #707070;
}

.DailogBtnGrp{
  margin-top: 45px;
}

.DailogBtn{
  width: 137px;
  height: 47px;
  font-size: 18px !important;
  color: #333333 !important;
  text-transform: capitalize !important;
}

.DailogNoBtn{
  border: 1px solid #DADADA !important;
  margin-right: 15px !important;
}

.DailogYesBtn{
  background-color: #F27979 !important;
  border: 1px solid #F27979 !important;
  color: #ffffff !important;
}

.Button{
  border-radius: 6px !important;
  border: 1px solid #E6E6E6 !important;
  background-color: #FFFFFF !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.Button span{
  color: #14345A;
  font-size: 15px;
  text-transform: capitalize;
}

.ImageCount{
  position: absolute;
  right: 17px;
  background-color: rgba(51, 51, 51, 0.39);
  color: #ffffff;
  padding: 3px;
  font-size: 20px;
  z-index: 1;
  border-radius: 9px;
  top: 17px;
}

.SvgIcon{
  cursor: pointer;
}

.SkipHand{
  display: none;
  position: absolute;
  cursor: pointer;
  left: 17px;
  top: 17px;
  z-index: 1;
}