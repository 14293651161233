.LogoutTitle{
    font-size: 32px !important;
    font-weight: 700 !important;
    color: #333333;
}

.LogoutSubTitle{
    font-size: 20px !important;
    color: #9A9A9A;
    margin-bottom: 55px !important;
    margin-top: 10px !important;
}

.LoginButton{
    width: 100%;
    background-color: #14345A !important;
    color: #ffffff !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
}