.Header{
    height: 60px;
    background-color: #ffffff;
    padding: 0 30px;
}

.DateTime{
    font-size: 14px;
    color: #9A9A9A;
}

.User{
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.List{
    position: absolute !important;
    right: 30px;
    width: 125px;
    top: 60px;
    background-color: #ffffff;
    box-shadow: 0px 3px 5px #e5e9eb;
    display: none;
}

.ListItem{
    justify-content: center !important;
}

.Show{
    display: block;
}

.MenuHolder{
    padding-left: 30px;
    height: 60px;
}

.MenuItemHolder{
    padding: 19px 0px;
    margin-right: 40px;
}

.MenuItem{
    text-decoration: none;
    color: #9A9A9A;
    font-size: 16px;
    line-height: 60px;
    padding: 19px 0px;
}

.Active{
    border-bottom: 2px solid #14345A;
}

.Active a{
    color: #333333;
}