.ReportsContainer{
    background-color: #ffffff;
    border-radius: 8px;
    /* margin-top: 20px; */
    padding: 40px 50px;
}

.ListContainer{
    border-bottom: 1px solid #E5E9EB;
    width: 100%;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.HeadingRow{
    padding-bottom: 30px;
}

.Heading{
    color: #333333;
    font-size: 20px;
    font-weight: 500;
    margin: auto !important;
}

.ListRow{
    padding: 10px 0 10px 0;
}

.ListItem,
.ListItemVal{
    color: #333333;
    font-size: 16px;
}

.ListItem{
    padding-left: 50px !important;
    position: relative;
}

.ListItem:before{
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #D5D5D5;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 34px;
}

.ListItemVal{
    font-weight: 500; 
}

ul{
    margin: 0;
}

.DateRoot{
    width: 230px;
    margin: 0 !important;
}

.DateRoot > div {
    padding-right: 0;
}

.LocationRoot{
    width: 228px;
}
.LocationInput{

}

.SelectOption{

}

.TimeRange{
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    color: #333333;
    padding-left: 7px;
}

.TimeRange > div{
    border: none;
}

.TimeRange input{
    padding: 0 !important;
}

.TimeRange .react-calendar__tile--hasActive{
    border-radius: 50%;
}

/* .TimeRange input:focus{
    outline: none;
} */

.LocationFilter{
    padding-right: 20px;
}

.Button {
    background-color: #14345A !important;
    color: #fff !important;
    text-transform: capitalize !important;
    height: 31px;
}

.DisabledButton{
    background-color: #818181 !important;
}

.Button img{
    width: 11px;
}

.FilterGrid{
    display: flex;
    align-items: center;
}

.FilterGrid p{
    position: absolute;
    top: 41px;
}

.ArrowImgHolder{
    height: 40px;
    border: 1px solid #AFC1DF;
    border-radius: 4px;
    width: 40px;
    position: relative;
    cursor: pointer;
}

.ArrowDisabled{
    pointer-events: none;
    opacity: 0.5;
}

.ArrowImg{
    width: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.DownloadLink{
    text-decoration: underline;
    cursor: pointer;
    color: #14345A;
    font-size: 16px;
}

.DownloadLink:hover{
    text-decoration: none;
}

.DisableDownloadLink{
    pointer-events: none;
}

.Backdrop{
    z-index: 9999 !important;
}

.DatePickerRoot{
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0 10px;
    font-size: 16px;
}

.DatePickerHolder{
    display: flex;
}

.DatePickerStart{
    height: 40px;
    border-radius: 4px 0 0 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-right: none;
    padding: 0 10px;
    font-size: 16px;
    width: 130px;
}

.DatePickerStart .react-datepicker__day--keyboard-selected{
    color: unset;
    background-color: unset;
}

.DatePickerEnd{
    height: 40px;
    border-radius: 0px 4px 4px 0;
    border: 1px solid rgba(0, 0, 0, 0.23);
    /* border-left: none; */
    padding: 0 10px;
    font-size: 16px;
    width: 130px;
}