.StatisticsContainer{
    background-color: #ffffff;
    border-radius: 8px;
    /* margin-top: 20px; */
    padding: 33px;
}

.DateRoot{
    width: 230px;
    margin: 0 !important;
}

.DateRoot > div {
    padding-right: 0;
}

.Label{
    font-size: 23px;
}

.Heading{
    color: #333333;
    font-size: 20px;
    font-weight: 500;
    margin: auto !important;
    padding-right: 65px;
}

.Button {
    background-color: #14345A !important;
    color: #fff !important;
    text-transform: capitalize !important;
    height: 31px;
}

.Button img{
    width: 11px;
}

.PdfContainer{
    background-color: #ffffff;
    border-radius: 8px;
    margin-top: 20px;
}

.PdfRow{
    border-bottom: 2px solid #F0F2F4;
    padding: 27px 33px 21px 33px;
}

.PdfHolder{
    padding: 33px;
    min-height: calc(100vh - 380px);
    width: 100%;
}

.PdfShow{
    padding: 33px;
    min-height: 100vh;
    width: 100%;
}

.PdfDiv{
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #F8F8F8;
    border-radius: 10px;
}

.Pdf{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: #333333;
}

.FilterGrid{
    display: flex;
    align-items: center;
}

.DefaultBtn{
    width: 145px;
    height: 45px;
    background-color: #14345A !important;
    text-transform: capitalize !important;
    color: #ffffff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.ArrowImgHolder{
    height: 40px;
    border: 1px solid #AFC1DF;
    border-radius: 4px;
    width: 40px;
    position: relative;
    cursor: pointer;
}

.ArrowImg{
    width: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Backdrop{
    z-index: 9999 !important;
}

.DisabledButton{
    background-color: #818181 !important;
}

.FilterGrid p{
    position: absolute;
    top: 41px;
}

.ArrowDisabled{
    pointer-events: none;
    opacity: 0.5;
}

.DatePickerRoot{
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0 10px;
    font-size: 16px;
}

.DatePickerRoot .react-datepicker__day--today, 
.DatePickerRoot .react-datepicker__month-text--today,
.DatePickerRoot .react-datepicker__quarter-text--today {
    font-weight: normal;
}

/* .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
    border-radius: 0.3rem;
    color: #216ba5 !important;
    background: #fff;
} */