/* .AuthContainer{
    height: 100vh;
} */
.AuthContainer{
    background-color: #ffffff;
}

.LeftGrid{
    width: 60%;
    height: 100vh;
}

.RightGrid{
    width: 40%;
    padding: 0 5%;
    margin-top: 120px !important;
}

.ImageArea{
    width: 100%;
    height: 100%;
}

.ImageArea img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.LogoImg{
    height: 42px;
    margin-bottom: 130px;
}