.LoginTitle{
    font-size: 32px !important;
    font-weight: 700 !important;
    color: #333333;
}

.LoginSubTitle{
    font-size: 20px !important;
    color: #9A9A9A;
    margin-bottom: 80px !important;
    margin-top: 10px !important;
}

.TextField{
    width:100%;
}

.TextField:first-child{
    margin-bottom: 30px !important;
}

.TextField input{
    font-size: 18px;
    color: #333333;
}

.TextField label{
    font-size: 18px;
    color: #9A9A9A;
}

.RemembermeLabel{
    font-size: 18px !important;
    color: #333333;
}

.LoginButton{
    width: 100%;
    background-color: #14345A !important;
    color: #ffffff !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
    margin-top: 40px !important;
}

.ShowPassword{
    width: 20px;
}

.Error{
    background-color: #FF272E;
    color: #FFFFFF;
    text-align: center;
    display: block;
    padding: 5px 20px;
    margin-top: 10px;
}