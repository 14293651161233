.SessionsContainer{
    background-color: #ffffff;
    border-radius: 8px;
    margin-top: 6px
}

.SessionsLabel{
    height: 65px;
    padding: 0 30px;
}

.TableTitle{
    font-size: 20px;
    color: #333333;
}

.TableContainer{
    box-shadow: none !important;
}

.StepperRoot{
    background-color: unset !important;
}

.StepConnector{
    border: 1px solid #E5E9EB;
    top: 28px !important;
}

.StepConnector span{
    border: none;
}

.StepIconActive,
.StepLabelActive{
    color: #63BF66 !important;
}

.StepIconCompleted,
.StepLabelCompleted{
    color: #ffffff !important;
}

.CompletedConnector,
.ActiveConnector{
    border-color: #63BF66;
}

.StepContentContainer{
    width: 80%;
    margin: 35px auto;
    position: relative;
}

.StepContent{
    background-color: #fbfbfb;
    border: 1px solid #CECECE;
    height: 514px;
    border-radius: 8px;
    position: relative;
}

.DetailsGrid{
    display: flex;
}

.ImageDiv,
.OtherDiv{
    height: 200px;
    background-color: #fbfbfb;
    border: 1px solid #CECECE;
    height: 514px;
    border-radius: 8px;
    position: relative;
    margin-bottom: 15px;
}

.ProbDiv{
    height: 514px;
    background-color: #fbfbfb;
    border: 1px solid #CECECE;
    padding: 15px;
    border-radius: 8px;
}

.ImageDiv{
    width: 50%;
    margin-right: 15px;
}

.OtherDiv{
    width: 70%;
    /* padding: 20px;; */
}

.TempCurve{
    min-height: 30vh;
    background-color: #fbfbfb;
    border: 1px solid #CECECE;
    border-radius: 8px;
    position: relative;
}

.CenterText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #9A9A9A;
}

.ChartContainer{
    height: 100%;
    width: 100%;
    margin: 20px;
    width: calc(100% - 40px);
    left: 0;
    transform: translate(0, -50%);
}

/* .StepContent{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

.ResultImage{
    height: 100%;
}

.FeedbackTitles{
    width: 20%;
    margin-right: 9% !important;
    border-right: 1px dashed #9A9A9A;
    line-height: 35px;
    height: 35px;
    font-size: 16px;
    color: #9A9A9A;
}

.FeedbackInputs{
    width: 70%;
    text-align: center;
    line-height: 35px;
    color: #707070;
    font-weight: 500;
    font-size: 16px;
}

.WTInput{
    width: 105px;
    height: 35px;
    border: 1px solid #EFE9EB !important;
    border-radius: 4px;
    background-color: #F5F5F5;
    padding: 0 15px !important;
    margin-left: 10px !important;
}

.WTInput input{
    font-size: 16px;
    font-weight: 500;
    color: #333333;
}

.WTInput > div:before,
.WTInput > div:after,
.WTInput > div:hover:before,
.WTInput > div:hover:after{
    border-bottom: none !important;
}

.FeedbackContainer{
    padding: 30px 0;
}

.FilterInput{
    height: 35px;
    padding: 0 40px 0 15px !important;
    width: 150px !important;
}

.FilterRoot label{
    transform: translate(14px, 15px) scale(1);
    font-size: 16px;
    font-weight: normal;
    color: #9A9A9A;
    top: -6px;
}

.FilterInput input {
    height: 35px;
    padding: 0 !important;
    font-size: 16px;
    font-weight: normal;
    color: #9A9A9A;
}

.FilterInput fieldset{
    border: 0.5px solid #A8B9D6;
}

.SelectOption{
    font-size: 14px;
    color: #333333;
}

.ActionContainer{
    margin-top: 30px;
}

.DefaultBtn{
    width: 145px;
    height: 45px;
    background-color: #14345A !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
}

.AltBtn{
    width: 145px;
    height: 45px;
    border: 1px solid #DADADA !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
}

.SecondaryBtn{
    width: 145px;
    height: 45px;
    background-color: #E2E2E2 !important;
    border: 1px solid #333333 !important;
    margin: 0 15px !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
}

.StepperButton{
    background: #fff;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    width: 230px;
    border-radius: 4px;
    border: 1px solid #63BF66;
    padding: 18px;
    flex-direction: row !important;
}

.StepperButton span > span{
    margin-top: 0 !important;
}

/* .StepperButton p{
    padding: 18px;
} */

/* .StepLabelIcon{
    display: none !important;
} */

.StepLabelCompleted .StepperButton{
    background: #63BF66;
    color: #ffffff;
}

.StepRoot,
.StepCompleted{
    font-size:10px;
    cursor: pointer;
}

.StepCompleted .StepperButton{
    border-color: #63BF66;
    background-color: #63BF66;
    color: #ffffff
}

.StepConnector + .StepperButton{
    border-color: #E5E9EB;
}

.ActiveConnector + .StepperButton{
    border-color: #63BF66;
}

.ButtonWrapper{
    position: absolute !important;
    text-align: center;
}

.AddImgBtn{
    text-transform: capitalize !important;
    border: 1px solid #EFE9EB !important;
    box-shadow: 0 4px 8px #95A3A8;
    background-color: #ffffff !important;
    color: #707070 !important;
}

.AddImg{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.TakeNew{
    right: 15px;
    top: 15px;
    position: absolute !important;
}

.PhotoBoothErr{
    color: red;
    padding-top: 20px;
}

.TextureMark{
    top: 18px;
    color: #ffffff;
}

/* .TextureMarkLabel{ 
    top: 45px !important;
} */

/* .TextureMarkLabel{
    transform: none !important;
    right: 0;
    left: unset
} */

.CommentInput{
    width: 100%;
    border: 1px solid #E5E9EF !important;
    border-radius: 4px;
    background-color: #F5F5F5;
    padding: 4px 10px !important;
}

.CommentInput > div:before,
.CommentInput > div:after,
.CommentInput > div:hover:before,
.CommentInput > div:hover:after{
    border-bottom: none !important;
}

.FeedbackRow:not(:last-child){
    margin-bottom: 90px;
}

.TextureRail,
.TextureTrack{
    height: 12px !important;
}

.TextureTrack{
    background-color: transparent !important;
    color: unset
}

.TextureRail{
    background: linear-gradient(106deg, rgba(245,246,250,1) 0%, rgba(99,191,102,1) 50%, rgba(248,81,79,1) 100%);
    opacity: 1 !important;
    border: 3px solid #fff;
    box-shadow: 0 0 5px #b7b3b3;
    border-radius: 8px !important;
}

.TextureThumb{
    height: 25px !important;
    width: 25px !important;
    color: #63BF66 !important;
    box-shadow: 0 0 5px #707070;
    border: 5px solid #ffffff;
    margin-top: -6px !important;
}

.SvgIcon{
    width: 17px;
    height:17px;
    vertical-align: middle;
}

.ImgSvg{
    width: 26px;
    height: 21px;
    margin-right: 10px; 
}

.TabelRow > td {
    width: 10%;
}

.SelectTabel{
    position: absolute;
    width: auto !important;
}

.SelectTabelBody{
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
    box-shadow: 0 4px 8px #95A3A8;
    border-radius: 8px;
    left: 0;
    top: 16px;
    max-height: 300px;
    overflow: auto;
    height: 0;
    transition: all 1s;
}

.SelectArrow{
    position: absolute;
    right: 15px;
    width: 15px;
    cursor: pointer;
    top: 27px;
}

.SelectRow:hover{
    background-color: #F3F3F3;
    cursor: pointer;
}

.ShowSelect{
    height: auto;
}

.SelectCell{
    background: #F5F5F5;
    margin: 10px 0;
    padding: inherit !important;
    background-clip: content-box;
    line-height: 35px !important;
}

.StepperText{
    padding-left: 10px;
    text-align: left;
}

.IMGLoder{
    text-align: center;
    font-size: 50px;
    color: rgb(154, 154, 154);
    padding-top: 200px;
    /* opacity: 0.5; */
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fbfbfb;
}

.DisableStep{
    pointer-events: none;
    /* display: none; */
    /* opacity: 0.5; */
}

.DisableStep .StepperButton{
    background-color: #ccc;
}

.CurveTitle{
    color: #9A9A9A;
    font-size: 15px;
}

Canvas{
    padding-top: 30px;
    height: 430px !important;
}